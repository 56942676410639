import React from 'react'
import ParticlesBg from "particles-bg";
import TextLoop from "react-text-loop"
import './App.css';
import { BsInstagram } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { BsLinkedin } from 'react-icons/bs';

function App() {
  const config_cursor ={
  show: true,
  blink: true,
  element: '',
  hideWhenDone: true,
  
  }
    return ( 
        <div>
          <div className = "card" >
            <div className = "card_body" >
              <div className = " text-center"> 
                 <div className="bg_content rd_12 p_8"> 
                  <div className='ideas'>
                    <em>Hi There, My Name Is Kenzo Salam</em>
                  </div>

                 currently working on a website to better communicate ideas.         
                 
                 </div>
                 <div className="bg_content rd_12 p_8 mt-16"> 
                   Interested In {' '}
                 <TextLoop interval={1000}>
                    <span className='higthlight'> Programming💻</span>
                    <span className='higthlight'> Investments📈</span>

                   
                   
                </TextLoop>{" "}
                 </div>
             </div> 
             <div className = "mt-16" >
                <a className = "btn_action bg_content" href = "https://www.linkedin.com/in/kenzo-salam-5a2b21254/" >
                <BsLinkedin/>
                <span>Linkedin</span>
                </a>  
            </div> 
            <div className = "mt-16" >
                <a className = "btn_action bg_content" href = "https://www.instagram.com/kenzosalam/" >
                  <BsInstagram/>
                  <span>Instagram</span>
                  </a>
             </div> 
            <div className = "mt-16" >
                <a className = "btn_action bg_content" href = "https://www.tiktok.com/@kenjo888?_t=8oysfJkxMBU&_r=1" >
                  <FaTiktok/>
                  <span>Tiktok</span>
                  
                </a>  
            </div> 
           
        </div> 
    </div> 
    <div className='bg'>
        <ParticlesBg type = "thick" bg = { true }/> 
        </div>  
        
   </div>
  );
}
export default App;

